import numeral from 'numeral'

export const columns = [
	{
		prop: 'driverName',
		label: '司机姓名',
	},
	{
		prop: 'driverPhone',
		label: '司机手机号',
		/* formatter: function (row, column, cellValue, index) {
			return cellValue === 20 ? '上架' : '下架'
		}, */
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		/* formatter: function (row, column, cellValue) {
			return numeral(cellValue).divide(10000).format('0,0.00')
		}, */
	},
	{
		prop: 'maintenanceManName',
		label: '维保专员',
	},
	{
		prop: 'count',
		label: '事故次数',
		type: 'link',
		width: 'auto',
	},
	{
		prop: 'companyName',
		label: '所属公司',
		width: 250,
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: 250,
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			label: '司机信息',
			attr: 'keyword',
			type: 'search',
			placeholder: '输入司机姓名手机号搜索',
			clearable: true,
			width: 250,
		},
		{
			attr: 'driverSupportIds',
			label: '司服伙伴',
			type: 'multi-select',
			placeholder: '请选择司服伙伴',
		},
		{
			attr: 'maintenanceManIds',
			label: '维保专员',
			type: 'multi-select',
			placeholder: '请选择维保专员',
		},
		{
			attr: 'companyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '请选择所属公司',
		},
	],
}
/*
name: '',
	seriesId: '',
	brandId: '',
	fuelType: '',
	outerColors: '',
	status: '',
	indicativePrice: '',
*/

export const rules = {}
